%page {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;

  visibility: hidden;
  width: 100%;
  z-index: z("content");
  height: 100vh;
  overflow: hidden;

  transition: opacity 0.5s ease, visibility 0.5s ease;
}

%page--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
  height: 100%;
  overflow: unset;
}

%content {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

%img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%wrapper {
  margin: auto;
  max-width: 130rem;
  width: 90%;

  @include media("<=phone") {
    width: calc(100% - 5rem);
  }
}

%octagon-border {
  position: relative;
  @extend %body-mono;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }

  .oct-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

%section-padding {
  padding: 0 24px;

  @include media("<=tablet") {
    padding: 0 16px;
  }
}
