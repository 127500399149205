.c-footer {
  @extend %section-padding;
  border-top: $border-dark;
  position: relative;

  @include media(">tablet") {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;
  }

  &__top {
    display: none;
  }

  &__linkarea {
    display: flex;
    align-items: center;

    @include media("<=tablet") {
      padding: 24px 0;
      justify-content: space-between;
    }
  }

  &__copyrightarea {
    display: flex;
    align-items: center;
  }

  &__star {
    display: grid;
    margin: 0 12px;
  }

  &__links {
    display: flex;
  }

  &__link {
    &__text {
      @extend %body-serif;
    }

    &__separator {
      @extend %body-serif;

      &::after {
        display: none;
      }
    }
  }

  &__spotify,
  &__left {
    display: flex;
    align-items: center;
  }

  &__spotify {
    width: 100%;
  }

  &__left {
    justify-content: space-between;

    @include media("<=tablet") {
      padding: 24px 0;
      border-bottom: $border-dark;
    }
  }

  .c-spotify {
    &__logo {
      margin-right: 8px;
      display: grid;
    }

    &__song {
      overflow: hidden;
      width: 100%;
      @include media(">tablet") {
        max-width: 302px;
      }
    }
  }

  .c-song {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:not(:first-of-type) {
      padding: 0 24px;
    }
  }
}

.back-top {
  .c-footer__top {
    display: block;
    @extend %body-serif;
    cursor: pointer;
    flex-shrink: 0;

    @include media(">tablet") {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include media("<=tablet") {
      margin-left: 24px;
      width: 105px;
      height: 40px;
      border: $border-light;
      border-radius: 40px;

      &::after {
        display: none;
      }

      span {
        display: none;
      }
    }
  }

  .c-footer {
    .c-spotify {
      @include media("<=tablet") {
        max-width: calc(100% - 121px);
      }
    }
  }
}

[data-marquee-spotify] {
  width: fit-content;
  position: relative;
  will-change: transform;
  display: flex;

  &.active {
    animation: marquee 25s linear infinite;
    animation-play-state: running;

    &:hover {
      animation-play-state: paused;
    }
  }

  @keyframes marquee {
    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }
}
