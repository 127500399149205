.notfound {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @extend %cover;
    height: auto;

    &__inner {
      display: flex;
      height: 100vh;
      flex-direction: column;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 96px;

    @include media(">tablet") {
      justify-content: center;
      flex-basis: 100%;
      margin-top: 0;
    }

    &__titles {
      display: grid;
      place-items: center;
      margin-bottom: 16px;

      .notfound__title {
        grid-area: 1/1;
        opacity: 0;
        text-align: center;
        pointer-events: none;
        @extend %h1-cond;

        &.active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &__desc {
      @extend %body-sans;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  &__footer {
    margin-top: auto;
  }
}
