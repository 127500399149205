.g-256__wrapper, .g-256, .notfound__wrapper, .notfound, .work__wrapper, .work, .about__wrapper, .about, .home__wrapper, .home {
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
}

a {
  font-family: inherit;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  outline-color: #90ff21;
}

a, button {
  color: inherit;
  font-size: inherit;
  text-shadow: 0 0 8px #cccfcabf;
  text-decoration: none;
}

ol, ul {
  list-style: none;
}

html {
  box-sizing: border-box;
  height: 100%;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  width: 100%;
  font-size: .694444vw;
}

@media (max-width: 375px) {
  html {
    font-size: 2.66667vw;
  }
}

@media (min-width: 769px) {
  html {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

body {
  text-transform: uppercase;
  letter-spacing: .28px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overscroll-behavior: none;
  width: 100%;
  color: #cccfca;
  text-shadow: 0 0 8px #cccfcabf;
  background: #080808;
  margin: 0;
  padding: 0;
  font-family: Suisse Condensed, -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

@media (min-width: 769px) {
  body {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

body.no-scroll {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

body ::selection {
  color: #080808;
  text-shadow: none;
  background: #90ff21;
}

canvas {
  z-index: 5;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

canvas.smoke {
  z-index: 10;
}

@media (max-width: 768px) {
  canvas.smoke {
    opacity: 0;
  }
}

.dg.ac {
  z-index: 10 !important;
}

.visually-hidden {
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute !important;
}

@font-face {
  font-family: Suisse Intl;
  src: url("SuisseIntl-Regular.2e2e9bc1.woff2") format("woff2"), url("SuisseIntl-Regular.8321300d.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Suisse Condensed;
  src: url("SuisseIntlCond-Regular.d9b5b729.woff2") format("woff2"), url("SuisseIntlCond-Regular.dbe9d354.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Suisse Condensed;
  src: url("SuisseIntlCond-Semibold.182a6ba2.woff2") format("woff2"), url("SuisseIntlCond-Semibold.b69bf5ad.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Suisse Mono;
  src: url("SuisseIntlMono-Regular.77bf9d0f.woff2") format("woff2"), url("SuisseIntlMono-Regular.b2dfa73c.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Suisse Works;
  src: url("SuisseWorks-Regular.ff47f064.woff2") format("woff2"), url("SuisseWorks-Regular.c131c1c2.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

.home__header__link, .about__credits__item .credits__link, .about__resume__item .resume__link, .c-button, .back-top .c-footer__top, .c-footer__link__separator, .c-footer__link__text, .c-nav__link, p a {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.home__header__link:after, .about__credits__item .credits__link:after, .about__resume__item .resume__link:after, .c-button:after, .back-top .c-footer__top:after, .c-footer__link__separator:after, .c-footer__link__text:after, .c-nav__link:after, p a:after {
  content: "";
  height: 1px;
  width: 100%;
  background: currentColor;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}

.home__header__link:hover:after, .about__credits__item .credits__link:hover:after, .about__resume__item .resume__link:hover:after, .c-button:hover:after, .back-top .c-footer__top:hover:after, .c-footer__link__separator:hover:after, .c-footer__link__text:hover:after, .c-nav__link:hover:after, p a:hover:after {
  transform-origin: 0;
  transform: scaleX(1);
}

.home__header__link:after, .about__credits__item .credits__link:after, .about__resume__item .resume__link:after, .c-button:after, .back-top .c-footer__top:after, .c-footer__link__separator:after, .c-footer__link__text:after, .c-nav__link:after, p a:after {
  transform-origin: 100%;
  transform: scaleX(0);
}

.home__header__link, .about__credits__item .credits__link, .about__resume__item .resume__link, .c-button, .back-top .c-footer__top, .c-footer__link__separator, .c-footer__link__text, .c-nav__link, p a {
  display: inline-block;
}

.home__playlist__image {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.home__playlist__image img, .home__playlist__image video {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
}

.preloader__number, .c-nav__time {
  justify-content: center;
  align-items: center;
  display: flex;
}

.work .c-project__media .loader, .home__gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.g-256, .notfound, .work, .about, .home {
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 100%;
  z-index: 6;
  height: 100vh;
  transition: opacity .5s, visibility .5s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.g-256--active, .notfound--active, .work--active, .about--active, .home--active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  height: 100%;
  overflow: unset;
  transition: none;
}

.g-256__main .d-card__content .d-card__image img, .work .c-project__media[data-size="large"] img, .work .c-project__media[data-size="large"] video, .work .c-project__media[data-size="small"] img, .work .c-project__media[data-size="small"] video, .about__info__image img, .about__info__image video, .about__hero__text .text-row__image--large img, .about__hero__text .text-row__image--large video, .about__hero__text .text-row__image--small img, .about__hero__text .text-row__image--small video, .about__hero__text .text-row__image img, .about__hero__text .text-row__image video, .home__playlist__image img, .home__playlist__image video, .home__playlist__link img, .home__playlist__link video, .home__main__image img, .home__main__image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section__label, .about__label, .preloader__number, .c-nav__time {
  position: relative;
}

.section__label svg, .about__label svg, .preloader__number svg, .c-nav__time svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
}

.section__label .oct-text, .about__label .oct-text, .preloader__number .oct-text, .c-nav__time .oct-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.work__contact, .work .c-project__info, .work__header, .about__main, .home__descarea, .home__header, .c-footer, .c-nav {
  padding: 0 24px;
}

@media (max-width: 768px) {
  .work__contact, .work .c-project__info, .work__header, .about__main, .home__descarea, .home__header, .c-footer, .c-nav {
    padding: 0 16px;
  }
}

.g-256__title, .notfound__main__titles .notfound__title, .work__title, .about__hero__text {
  letter-spacing: -2.4px;
  font-feature-settings: "case" on;
  font-size: 96px;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 768px) {
  .g-256__title, .notfound__main__titles .notfound__title, .work__title, .about__hero__text {
    letter-spacing: -1.6px;
    font-size: 64px;
  }
}

.work .c-project__title, .home__header, .preloader__title, .about__contact a, .about__bio__desc a {
  letter-spacing: -2.4px;
  text-transform: none;
  font-family: Suisse Works, serif;
  font-size: 48px;
  line-height: 1.15;
}

@media (max-width: 768px) {
  .work .c-project__title, .home__header, .preloader__title, .about__contact a, .about__bio__desc a {
    letter-spacing: -1.6px;
    font-size: 32px;
  }
}

.work .c-project__title:after, .home__header:after, .preloader__title:after, .about__contact a:after, .about__bio__desc a:after {
  height: 1.5px;
}

.about__contact, .about__bio__desc {
  letter-spacing: -1.2px;
  text-transform: none;
  font-family: Suisse Intl, sans-serif;
  font-size: 48px;
  line-height: 1.15;
}

@media (max-width: 768px) {
  .about__contact, .about__bio__desc {
    letter-spacing: -.8px;
    font-size: 32px;
  }
}

.about__credits__item .credits__link, .about__resume__item .resume__link, .c-button, .back-top .c-footer__top, .c-footer__link__separator, .c-footer__link__text, .c-nav__link, p a {
  letter-spacing: -.35px;
  text-transform: none;
  font-family: Suisse Works, serif;
  line-height: 1.3;
}

.notfound__main__desc, .work__subtitle, .about__bonus, .about__bio__disclaimer, .about__hero__desc {
  letter-spacing: normal;
  text-transform: none;
  font-family: Suisse Intl, sans-serif;
  line-height: 1.3;
}

.g-256__main .d-card, .cursor, .section__label, .about__label, .preloader__number, .c-nav__time {
  letter-spacing: normal;
  font-family: Suisse Mono, monospace;
  font-size: 12px;
  line-height: 1.3;
}

.c-nav {
  height: 51px;
  border-bottom: 1px solid #202020;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-nav__link {
  text-transform: capitalize;
}

.c-nav__time {
  width: 86px;
  height: 24px;
  flex-shrink: 0;
  position: relative;
}

.c-nav__time .flash {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 9;
  background: #080808;
  padding: 24px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .preloader {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.preloader__wrapper {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 768px) {
  .preloader__wrapper {
    display: block;
  }
}

.preloader__title {
  margin-right: 18px;
}

@media (max-width: 768px) {
  .preloader__title {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

@media (min-width: 769px) {
  .preloader__right {
    margin-top: 12px;
  }
}

.preloader__right__top {
  margin-bottom: 32px;
}

.preloader__right__bottom {
  max-width: 200px;
  margin-top: 16px;
}

.preloader__desc {
  opacity: 0;
  margin-bottom: 24px;
  transition: opacity 1s;
}

.preloader__desc.active {
  opacity: 1;
}

.preloader__button {
  opacity: 0;
  pointer-events: none;
}

.preloader__button.active {
  opacity: 1;
  pointer-events: auto;
}

.preloader__info {
  margin-top: 23px;
}

.preloader__line {
  height: 1px;
  width: 100%;
  transform-origin: 0;
  background: #202020;
  transition: transform .25s;
  transform: scaleX(0);
}

.preloader__number__con {
  align-items: center;
  display: flex;
}

.preloader__label {
  margin-bottom: 8px;
}

.preloader__number {
  width: 40px;
  height: 24px;
  position: relative;
}

.preloader__icon {
  margin-right: 8px;
  display: grid;
}

.c-footer {
  border-top: 1px solid #202020;
  position: relative;
}

@media (min-width: 769px) {
  .c-footer {
    height: 51px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.c-footer__top {
  display: none;
}

.c-footer__linkarea {
  align-items: center;
  display: flex;
}

@media (max-width: 768px) {
  .c-footer__linkarea {
    justify-content: space-between;
    padding: 24px 0;
  }
}

.c-footer__copyrightarea {
  align-items: center;
  display: flex;
}

.c-footer__star {
  margin: 0 12px;
  display: grid;
}

.c-footer__links {
  display: flex;
}

.c-footer__link__separator:after {
  display: none;
}

.c-footer__spotify, .c-footer__left {
  align-items: center;
  display: flex;
}

.c-footer__spotify {
  width: 100%;
}

.c-footer__left {
  justify-content: space-between;
}

@media (max-width: 768px) {
  .c-footer__left {
    border-bottom: 1px solid #202020;
    padding: 24px 0;
  }
}

.c-footer .c-spotify__logo {
  margin-right: 8px;
  display: grid;
}

.c-footer .c-spotify__song {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 769px) {
  .c-footer .c-spotify__song {
    max-width: 302px;
  }
}

.c-footer .c-song {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.c-footer .c-song:not(:first-of-type) {
  padding: 0 24px;
}

.back-top .c-footer__top {
  cursor: pointer;
  flex-shrink: 0;
  display: block;
}

@media (min-width: 769px) {
  .back-top .c-footer__top {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .back-top .c-footer__top {
    width: 105px;
    height: 40px;
    border: 1px solid #484b45;
    border-radius: 40px;
    margin-left: 24px;
  }

  .back-top .c-footer__top:after, .back-top .c-footer__top span {
    display: none;
  }
}

@media (max-width: 768px) {
  .back-top .c-footer .c-spotify {
    max-width: calc(100% - 121px);
  }
}

[data-marquee-spotify] {
  width: fit-content;
  will-change: transform;
  display: flex;
  position: relative;
}

[data-marquee-spotify].active {
  animation: marquee 25s linear infinite;
}

[data-marquee-spotify].active:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.c-button {
  cursor: pointer;
  border: 1px solid #484b45;
  border-radius: 40px;
  justify-content: start;
  align-items: center;
  padding: 11px 16px;
  transition: border-color .25s, color .25s, opacity 1s .5s;
  display: inline-flex;
}

.c-button:after {
  display: none;
}

@media (any-pointer: fine) {
  .c-button:hover {
    filter: drop-shadow(0 0 8px #90ff2180);
    color: #90ff21;
    border: 1px solid #90ff21;
    box-shadow: 0 0 8px #90ff2180;
  }
}

.c-button .c-button__icon {
  margin-left: 4px;
  display: grid;
}

.cursor {
  display: none;
}

.cursor__item {
  backdrop-filter: blur(32px);
  color: #000;
  height: 24px;
  background: #ffffff80;
  grid-area: 1 / 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cursor__expand {
  clip-path: polygon(0 17px, 0 7px, 7px 0, 56px 0, 63px 7px, 63px 17px, 56px 24px, 7px 24px, 0 17px);
  width: 63px;
}

.cursor__collapse {
  opacity: 0;
  clip-path: polygon(0 17px, 0 7px, 7px 0, 72px 0, 79px 7px, 79px 17px, 72px 24px, 7px 24px, 0 17px);
  width: 79px;
}

.cursor.active .cursor__expand {
  opacity: 0;
}

.cursor.active .cursor__collapse {
  opacity: 1;
}

@media (any-pointer: fine) {
  .cursor {
    pointer-events: none;
    z-index: 8;
    opacity: 1;
    place-items: center;
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@media (any-pointer: fine) and (max-width: 768px) {
  .cursor {
    display: none;
  }
}

@media (any-pointer: fine) {
  .no-js .cursor {
    display: none;
  }
}

.home__wrapper {
  height: auto;
}

@media (min-width: 769px) {
  .home__wrapper__inner {
    height: 100vh;
    flex-direction: column;
    display: flex;
  }
}

.home__gradient {
  pointer-events: none;
  max-width: 50vw;
  background: radial-gradient(50% 50%, #90ff2106 0%, #08080800 100%, #90ff2100 100%);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .home__gradient {
    display: none;
  }
}

.home__header {
  justify-content: space-between;
  margin-top: 24px;
  display: flex;
}

.home__header__link:after {
  height: 1.5px;
}

.home__header__link:nth-child(2):after {
  left: unset;
  width: 90%;
  right: 0;
}

.home__main {
  margin-top: auto;
}

.home__main__image {
  width: 100%;
  aspect-ratio: 1;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(1 / 1)) {
  .home__main__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home__main__image img, .home__main__image video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .home__main__image {
    display: none;
  }
}

.home__main__image__inner {
  width: 100%;
  height: 100%;
  display: none;
}

@media (min-width: 769px) {
  .home__descarea {
    justify-content: space-between;
    display: flex;
  }
}

.home__statusarea {
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.home__status {
  width: 100%;
}

@media (min-width: 769px) {
  .home__status {
    max-width: 194px;
    text-align: right;
    margin-right: 24px;
  }
}

.home__status__label {
  width: 63px;
  height: 24px;
  margin-bottom: 16px;
  display: grid;
}

@media (min-width: 769px) {
  .home__status__label {
    margin-left: auto;
  }
}

.home__playlist {
  max-width: 112px;
  width: 100%;
}

@media (max-width: 768px) {
  .home__playlist {
    display: none;
  }
}

.home__playlist__link {
  width: 100%;
  aspect-ratio: 1;
  border: 1px solid #484b45;
  border-radius: 12px;
  padding: 4px;
  font-size: 0;
  display: grid;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(1 / 1)) {
  .home__playlist__link:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home__playlist__link img, .home__playlist__link video {
  position: absolute;
  top: 0;
  left: 0;
}

.home__playlist__link figure {
  grid-area: 1 / 1;
  transition: opacity .25s linear;
}

.home__playlist__link figure:last-child {
  opacity: 0;
}

.home__playlist__link:hover figure:last-child {
  opacity: 1;
}

.home__playlist__image {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(1 / 1)) {
  .home__playlist__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home__playlist__image img, .home__playlist__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.home__about {
  width: 100%;
  margin-bottom: 48px;
}

@media (min-width: 769px) {
  .home__about {
    max-width: 330px;
    margin-bottom: 0;
  }
}

.home__about__icons {
  margin-bottom: 16px;
  display: grid;
}

.home__about__icons__inner {
  gap: 8px;
  display: inline-flex;
}

@supports not (gap: 8px 8px) {
  .home__about__icons__inner {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .home__about__icons__inner > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.home__about__icons__inner .section__label {
  width: 40px;
  height: 24px;
}

.home__footer {
  margin-top: 24px;
}

.about__wrapper {
  height: auto;
}

.about__main {
  max-width: 1204px;
  margin: 120px auto;
}

@media (max-width: 768px) {
  .about__main {
    margin-top: 16px;
    margin-bottom: 48px;
  }
}

.about__hero {
  margin-bottom: 200px;
}

@media (max-width: 768px) {
  .about__hero {
    margin-bottom: 96px;
  }
}

.about__hero__label {
  width: 63px;
  height: 24px;
  margin-bottom: 24px;
  display: grid;
}

.about__hero__text {
  hyphens: auto;
  word-break: break-word;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .about__hero__text {
    margin-bottom: 16px;
  }
}

.about__hero__text .text-row__image {
  max-width: 72px;
  width: 100%;
  aspect-ratio: 1;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(1 / 1)) {
  .about__hero__text .text-row__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.about__hero__text .text-row__image img, .about__hero__text .text-row__image video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .about__hero__text .text-row__image {
    max-width: 48px;
  }
}

.about__hero__text .text-row__image--small img, .about__hero__text .text-row__image--small video {
  clip-path: polygon(0 51px, 0 21px, 21px 0, 51px 0, 72px 21px, 72px 51px, 51px 72px, 21px 72px, 0 51px);
}

@media (max-width: 768px) {
  .about__hero__text .text-row__image--small img, .about__hero__text .text-row__image--small video {
    clip-path: polygon(34px 48px, 48px 34px, 48px 14px, 34px 1px, 14px 1px, 1px 14px, 1px 34px, 14px 48px, 34px 48px);
  }
}

.about__hero__text .text-row__image--large {
  max-width: 144px;
  aspect-ratio: 2;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(144 / 72)) {
  .about__hero__text .text-row__image--large:after {
    content: "";
    width: 100%;
    padding-top: 50%;
    display: inline-block;
  }
}

.about__hero__text .text-row__image--large img, .about__hero__text .text-row__image--large video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .about__hero__text .text-row__image--large {
    max-width: 96px;
  }
}

.about__hero__text .text-row__image--large img, .about__hero__text .text-row__image--large video {
  clip-path: polygon(21px 72px, 1px 51px, 1px 21px, 21px 1px, 123px 1px, 144px 21px, 144px 51px, 123px 72px, 21px 72px);
}

@media (max-width: 768px) {
  .about__hero__text .text-row__image--large {
    clip-path: polygon(14px 48px, 1px 34px, 1px 14px, 14px 1px, 82px 1px, 96px 14px, 96px 34px, 82px 48px, 14px 48px);
  }
}

@media (max-width: 425px) {
  .about__hero__text .text-row .break-mobile {
    word-break: break-all;
  }
}

@media (max-width: 425px) {
  .about__hero__text .text-row .block-mobile {
    display: block;
  }
}

.about__info {
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .about__info {
    flex-direction: column;
  }
}

.about__info__left {
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;
  display: flex;
}

@media (min-width: 769px) {
  .about__info__left {
    max-width: 684px;
    margin-bottom: 0;
    margin-right: 48px;
  }
}

.about__info__right {
  width: 100%;
}

@media (min-width: 769px) {
  .about__info__right {
    max-width: 330px;
  }
}

.about__info__right__item {
  margin-bottom: 48px;
}

@media (min-width: 769px) {
  .about__info__right__item {
    text-align: center;
    margin-bottom: 96px;
  }
}

@media (min-width: 769px) {
  .about__info__right__footer {
    max-width: 684px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 1096px) {
  .about__info__right__footer {
    max-width: 70rem;
  }
}

@media (max-width: 768px) {
  .about__info__right__footer {
    max-width: unset;
  }
}

.about__info__image {
  width: 100%;
  aspect-ratio: .75;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(330 / 440)) {
  .about__info__image:after {
    content: "";
    width: 100%;
    padding-top: 133.333%;
    display: inline-block;
  }
}

.about__info__image img, .about__info__image video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .about__info__image {
    margin: 48px 0;
  }
}

.about__bio {
  margin-bottom: 120px;
  display: grid;
}

@media (max-width: 768px) {
  .about__bio {
    margin-bottom: 48px;
  }
}

.about__bio__labels {
  gap: 8px;
  display: inline-flex;
}

@supports not (gap: 8px 8px) {
  .about__bio__labels {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .about__bio__labels > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.about__bio__labels .section__label {
  height: 24px;
}

.about__bio__labels .section__label:first-of-type {
  width: 55px;
}

.about__bio__labels .section__label:last-of-type {
  width: 32px;
}

.about__bio__desc {
  margin-top: 24px;
  margin-bottom: 16px;
}

.about__label {
  height: 24px;
  margin-bottom: 24px;
  display: inline-flex;
}

.about__label--focus {
  width: 55px;
}

.about__label--shouts {
  width: 63px;
}

.about__label--clients, .about__label--resume {
  width: 71px;
}

.about__label--credits {
  width: 79px;
}

.about__links__labels {
  text-align: left;
  gap: 8px;
  display: inline-flex;
}

@supports not (gap: 8px 8px) {
  .about__links__labels {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .about__links__labels > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (min-width: 769px) {
  .about__links__labels {
    justify-content: center;
  }
}

.about__links__labels .section__label {
  height: 24px;
}

.about__links__labels .section__label:first-of-type {
  width: 32px;
}

.about__links__labels .section__label:not(:first-of-type) {
  width: 40px;
}

.about__links__list {
  max-width: 210px;
  margin: 24px auto 0;
}

@media (max-width: 768px) {
  .about__links__list {
    margin-left: 0;
  }
}

.about__resume__item:not(:last-of-type), .about__credits__item:not(:last-of-type), .about__bonus {
  margin-bottom: 24px;
}

.about__mark {
  color: #080808;
  background: #cccfca;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  display: flex;
  box-shadow: 0 0 8px #cccfcabf;
}

@media (max-width: 768px) {
  .about__mark {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
  }
}

.about__mark__text {
  margin-bottom: 16px;
}

@media (min-width: 769px) {
  .about__mark__text {
    max-width: 200px;
    margin-bottom: 14px;
  }
}

@media (max-width: 768px) {
  .about__mark__left {
    margin-bottom: 32px;
  }
}

.about__mark__icons {
  gap: 8px;
  display: inline-flex;
}

@supports not (gap: 8px 8px) {
  .about__mark__icons {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .about__mark__icons > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.about__mark__left, .about__mark__right {
  display: grid;
}

.work__wrapper {
  height: auto;
}

.work__wrapper__inner {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.work__footer {
  margin-top: auto;
}

.work__main {
  margin-top: 24px;
}

@media (min-width: 769px) {
  .work__header {
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    display: flex;
  }

  @supports not (gap: 24px 24px) {
    .work__header {
      --row-gap: 24px;
      --column-gap: 24px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .work__header > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.work__title {
  text-align: right;
}

@media (max-width: 425px) {
  .work__title__line .block-mobile {
    display: block;
  }

  .work__title__line .block-mobile.left {
    text-align: left;
  }

  .work__title__line .block-mobile.center {
    text-align: center;
  }
}

.work__subtitle {
  max-width: 330px;
}

@media (max-width: 768px) {
  .work__subtitle {
    margin-top: 24px;
    margin-left: auto;
  }
}

@media (max-width: 425px) {
  .work__subtitle {
    margin-left: unset;
  }
}

.work__subtitle__para {
  margin-top: 8px;
}

@media (max-width: 768px) {
  .work__subtitle__para {
    margin-top: 0;
  }
}

.work__eyes {
  margin-right: 16px;
  display: inline-block;
}

@media (max-width: 768px) {
  .work__eyes {
    display: none;
  }
}

.work__projects {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .work__projects {
    margin-top: 48px;
  }
}

.work .c-project {
  border-top: 1px solid #202020;
  border-bottom: 1px solid #202020;
}

.work .c-project:not(:first-of-type) {
  border-top: 0;
}

.work .c-project__header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

@media (max-width: 768px) {
  .work .c-project__header {
    pointer-events: none;
    display: block;
  }
}

.work .c-project__header__left {
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 769px) {
  .work .c-project__header__left {
    pointer-events: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }
}

.work .c-project__header__right {
  flex-shrink: 0;
  display: flex;
}

.work .c-project__controls {
  border-top: 1px solid #484b45;
  margin: 40px 16px 0;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.work .c-project__controls .c-project__link {
  flex-shrink: 0;
}

.work .c-project__controls .c-project__expand {
  width: 100%;
  justify-content: center;
}

.work .c-project__expand, .work .c-project__controls {
  pointer-events: auto;
}

@media (min-width: 769px) {
  .work .c-project__expand, .work .c-project__controls {
    display: none;
  }
}

.work .c-project__title {
  margin-right: 16px;
}

@media (max-width: 768px) {
  .work .c-project__title {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.work .c-project__timelinecon {
  margin-top: 4px;
}

.work .c-project__body {
  max-height: 0;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .work .c-project__body:after {
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#20202000 0%, #202020 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.work .c-project.active .c-project__body {
  max-height: unset;
  margin-bottom: 96px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .work .c-project.active .c-project__body {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .work .c-project.active .c-project__header__left {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .work .c-project.active .c-project__header__right {
    display: none;
  }
}

.work .c-project__carousel {
  height: 400px;
  cursor: grab;
  padding-left: 8px;
  display: inline-flex;
}

.work .c-project__carousel.dragging {
  cursor: grabbing;
}

@media (max-width: 768px) {
  .work .c-project__carousel {
    height: 240px;
    padding-left: 0;
  }
}

.work .c-project__media {
  height: 100%;
  pointer-events: none;
  user-select: none;
  margin-left: 16px;
  position: relative;
}

.work .c-project__media[data-size="small"] {
  aspect-ratio: 1;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(400 / 400)) {
  .work .c-project__media[data-size="small"] {
    width: 400px;
    height: 400px;
  }
}

.work .c-project__media[data-size="small"] img, .work .c-project__media[data-size="small"] video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .work .c-project__media[data-size="small"] {
    aspect-ratio: 1;
    font-size: 0;
    position: relative;
    overflow: hidden;
  }

  @supports not (aspect-ratio: calc(240 / 240)) {
    .work .c-project__media[data-size="small"] {
      width: 240px;
      height: 240px;
    }
  }

  .work .c-project__media[data-size="small"] img, .work .c-project__media[data-size="small"] video {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.work .c-project__media[data-size="large"] {
  aspect-ratio: 1.5;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports not (aspect-ratio: calc(600 / 400)) {
  .work .c-project__media[data-size="large"] {
    width: 600px;
    height: 400px;
  }
}

.work .c-project__media[data-size="large"] img, .work .c-project__media[data-size="large"] video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .work .c-project__media[data-size="large"] {
    aspect-ratio: 1.5;
    font-size: 0;
    position: relative;
    overflow: hidden;
  }

  @supports not (aspect-ratio: calc(360 / 240)) {
    .work .c-project__media[data-size="large"] {
      width: 360px;
      height: 240px;
    }
  }

  .work .c-project__media[data-size="large"] img, .work .c-project__media[data-size="large"] video {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.work .c-project__media img, .work .c-project__media video {
  pointer-events: none;
  user-select: none;
}

.work .c-project__media img {
  opacity: 0;
}

.work .c-project__media .loader {
  color: #484b45;
  z-index: inherit;
  z-index: 1;
  background: #202020;
  justify-content: center;
  align-items: center;
  display: flex;
}

.work .c-project__media .loader__star {
  margin-right: 8px;
}

.work .c-project__media .loader__copy {
  color: #484b45;
  text-shadow: none;
  font-size: 20px;
}

.work .c-project__media.loaded .loader {
  display: none;
}

.work .c-project__media.loaded img {
  opacity: 1;
}

.work .c-project__infoarea {
  margin-top: 40px;
}

.work .c-project__info {
  flex-wrap: wrap;
  gap: 4.8rem;
  display: flex;
}

@supports not (gap: 4.8rem 4.8rem) {
  .work .c-project__info {
    --row-gap: 4.8rem;
    --column-gap: 4.8rem;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .work .c-project__info > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .work .c-project__info {
    flex-direction: column;
    gap: 32px;
    display: inline-flex;
  }

  @supports not (gap: 32px 32px) {
    .work .c-project__info {
      --row-gap: 32px;
      --column-gap: 32px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .work .c-project__info > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

@media (min-width: 769px) {
  .work .c-project__info__item {
    max-width: 312px;
    width: 100%;
  }
}

.work .c-project__info .section__label {
  height: 24px;
  margin-bottom: 16px;
}

.work .c-project__about__label {
  width: 55px;
}

.work .c-project__role__label {
  width: 48px;
}

.work .c-project__collab__label {
  width: 117px;
}

.work .c-project__shouts__label {
  width: 63px;
}

.work .c-project__link {
  pointer-events: auto;
  margin-right: 16px;
}

.work__contact {
  margin-top: 200px;
  margin-bottom: 248px;
}

@media (max-width: 768px) {
  .work__contact {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.work__contact__labels {
  display: flex;
}

@media (min-width: 769px) {
  .work__contact__labels {
    justify-content: center;
  }
}

.work__contact__labels .section__label {
  height: 24px;
}

.work__contact__labels .section__label:first-of-type {
  width: 55px;
  margin-right: 8px;
}

.work__contact__labels .section__label:last-of-type {
  width: 48px;
}

.work__contact__text {
  max-width: 684px;
  text-align: center;
  margin: 24px auto 0;
}

@media (max-width: 768px) {
  .work__contact__text {
    text-align: left;
    margin-left: unset;
  }
}

.notfound__wrapper {
  height: auto;
}

.notfound__wrapper__inner {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.notfound__main {
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  display: flex;
}

@media (min-width: 769px) {
  .notfound__main {
    flex-basis: 100%;
    justify-content: center;
    margin-top: 0;
  }
}

.notfound__main__titles {
  place-items: center;
  margin-bottom: 16px;
  display: grid;
}

.notfound__main__titles .notfound__title {
  opacity: 0;
  text-align: center;
  pointer-events: none;
  grid-area: 1 / 1;
}

.notfound__main__titles .notfound__title.active {
  opacity: 1;
  pointer-events: auto;
}

.notfound__main__desc {
  text-align: center;
  margin-bottom: 24px;
}

.notfound__footer {
  margin-top: auto;
}

.g-256__wrapper {
  height: auto;
}

.g-256__wrapper__inner {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.g-256__wrapper__inner .c-nav {
  flex-shrink: 0;
}

.g-256__title {
  color: #202020;
  text-align: center;
  text-shadow: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.g-256__main {
  flex-direction: column;
  flex-basis: 100%;
  display: flex;
  overflow: auto;
}

.g-256__main .d-card {
  width: auto;
  z-index: 1;
  position: absolute;
}

.g-256__main .d-card__inner {
  width: auto;
  will-change: transform;
  background: #202020;
  border: 1px solid #ffffff0d;
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
}

.g-256__main .d-card__inner.is-pointer-down .d-card__header {
  cursor: grabbing;
}

.g-256__main .d-card__header {
  width: auto;
  cursor: grab;
  text-align: center;
  background: #202020;
  padding-top: 8px;
  padding-bottom: 12px;
  position: relative;
}

.g-256__main .d-card__header__buttons {
  display: flex;
  position: absolute;
  top: 10px;
  left: 8px;
}

.g-256__main .d-card__header__button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.g-256__main .d-card__header__button:first-child {
  background: #484b45;
  margin-right: 4px;
}

.g-256__main .d-card__header__button:last-child {
  background: #202020;
  border: 1px solid #484b45;
}

.g-256__main .d-card__content {
  width: 100%;
  background: #202020;
  padding: 0 8px 8px;
  display: block;
}

.g-256__main .d-card__content.small {
  width: 176px;
  height: 168px;
}

.g-256__main .d-card__content.medium {
  width: 256px;
  height: 248px;
}

.g-256__main .d-card__content .d-card__image {
  width: 100%;
  height: 100%;
}

.g-256__main .d-card__content.auto .d-card__image {
  max-height: 256px;
}

.g-256__main .d-card__content.auto .d-card__image img {
  max-height: 256px;
  height: auto;
  width: auto;
}

.g-256__footer {
  margin-top: auto;
}

/*# sourceMappingURL=index.031f2ad5.css.map */
