@import "utils/breakpoints";
@import "utils/mixins";
@import "utils/functions";

@import "core/variables";
@import "core/reset";
@import "core/globals";
@import "core/fonts";

@import "shared/links";
@import "shared/layout";
@import "shared/sections";
@import "shared/fonts";

@import "components/nav";
@import "components/preloader";
@import "components/footer";
@import "components/button";
@import "components/cursor";

@import "pages/home";
@import "pages/about";
@import "pages/work";
@import "pages/404";
@import "pages/256";
