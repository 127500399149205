@font-face {
  font-family: "Suisse Intl";
  src: url("../assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
    url("../assets/fonts/SuisseIntl-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Condensed";
  src: url("../assets/fonts/SuisseIntlCond-Regular.woff2") format("woff2"),
    url("../assets/fonts/SuisseIntlCond-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Condensed";
  src: url("../assets/fonts/SuisseIntlCond-Semibold.woff2") format("woff2"),
    url("../assets/fonts/SuisseIntlCond-Semibold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Mono";
  src: url("../assets/fonts/SuisseIntlMono-Regular.woff2") format("woff2"),
    url("../assets/fonts/SuisseIntlMono-Regular.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Works";
  src: url("../assets/fonts/SuisseWorks-Regular.woff2") format("woff2"),
    url("../assets/fonts/SuisseWorks-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
