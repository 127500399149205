.home {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @extend %cover;
    height: auto;

    &__inner {
      @include media(">tablet") {
        height: 100vh;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__gradient {
    @extend %element-cover;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(144, 255, 33, 0.025) 0%,
      rgba(8, 8, 8, 0) 100%,
      rgba(144, 255, 33, 0) 100%
    );
    pointer-events: none;
    max-width: 50vw;
    left: 50%;
    transform: translateX(-50%);

    @include media("<=tablet") {
      display: none;
    }
  }

  &__header {
    @extend %section-padding;
    @extend %h2-serif;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    &__link {
      @extend %link--hidden;

      &::after {
        height: 1.5px;
      }

      &:nth-child(2) {
        &::after {
          left: unset;
          right: 0;
          width: 90%;
        }
      }
    }
  }

  &__main {
    margin-top: auto;

    &__image {
      margin-top: 8px;
      margin-bottom: 16px;
      width: 100%;
      @include ratio(1, 1);

      @include media(">tablet") {
        display: none;
      }

      &__inner {
        width: 100%;
        height: 100%;
        display: none;
      }

      img {
        @extend %img;
      }
    }
  }

  &__descarea {
    @extend %section-padding;

    @include media(">tablet") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__statusarea {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__status {
    width: 100%;

    @include media(">tablet") {
      max-width: 194px;
      margin-right: 24px;
      text-align: right;
    }

    &__label {
      margin-bottom: 16px;
      display: grid;
      width: 63px;
      height: 24px;

      @include media(">tablet") {
        margin-left: auto;
      }
    }
  }

  &__playlist {
    max-width: 112px;
    width: 100%;

    @include media("<=tablet") {
      display: none;
    }

    &__link {
      width: 100%;
      @include ratio(1, 1);
      padding: 4px;
      border: $border-light;
      border-radius: 12px;
      display: grid;

      figure {
        grid-area: 1/1;
        transition: opacity 0.25s linear;

        &:last-child {
          opacity: 0;
        }
      }

      &:hover {
        figure {
          &:last-child {
            opacity: 1;
          }
        }
      }
    }

    &__image {
      width: 100%;
      @include ratio(1, 1);
      border-radius: 8px;
      @extend %ovh;
    }
  }

  &__about {
    width: 100%;
    margin-bottom: 48px;

    @include media(">tablet") {
      max-width: 330px;
      margin-bottom: 0;
    }

    &__icons {
      margin-bottom: 16px;
      display: grid;
      &__inner {
        @include flex-gap(8px);

        .section__label {
          width: 40px;
          height: 24px;
        }
      }
    }

    &__desc {
    }
  }

  &__footer {
    margin-top: 24px;
  }
}
