.work {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @extend %cover;
    height: auto;

    &__inner {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  }

  &__footer {
    margin-top: auto;
  }

  &__main {
    margin-top: 24px;
  }

  &__header {
    @extend %section-padding;

    @include media(">tablet") {
      @include flex-gap(24px);
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      align-items: flex-start;
    }
  }

  &__title {
    @extend %h1-cond;
    text-align: right;

    &__line {
      @include media("<=phone") {
        .block-mobile {
          display: block;

          &.left {
            text-align: left;
          }

          &.center {
            text-align: center;
          }
        }
      }
    }
  }

  &__subtitle {
    @extend %body-sans;
    max-width: 330px;

    @include media("<=tablet") {
      margin-left: auto;
      margin-top: 24px;
    }

    @include media("<=phone") {
      margin-left: unset;
    }

    &__para {
      margin-top: 8px;

      @include media("<=tablet") {
        margin-top: 0;
      }
    }
  }

  &__eyes {
    display: inline-block;
    margin-right: 16px;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__projects {
    margin-top: 24px;

    @include media("<=tablet") {
      margin-top: 48px;
    }
  }

  .c-project {
    border-top: $border-dark;
    border-bottom: $border-dark;

    &:not(:first-of-type) {
      border-top: 0;
    }

    &__header {
      padding: 24px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media("<=tablet") {
        display: block;
        pointer-events: none;
      }

      &__left {
        margin-bottom: 24px;
        width: 100%;
        @include media(">tablet") {
          display: flex;
          align-items: center;
          pointer-events: none;
          margin-bottom: 0;
        }
      }

      &__right {
        display: flex;
        flex-shrink: 0;
      }
    }

    &__controls {
      margin: 0 16px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: $border-light;
      margin-top: 40px;
      display: flex;

      .c-project__link {
        flex-shrink: 0;
      }

      .c-project__expand {
        width: 100%;
        justify-content: center;
      }
    }

    &__expand,
    &__controls {
      pointer-events: auto;

      @include media(">tablet") {
        display: none;
      }
    }

    &__title {
      @extend %h2-serif;
      margin-right: 16px;

      @include media("<=tablet") {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    &__timelinecon {
      margin-top: 4px;
    }

    &__body {
      max-height: 0;
      overflow: hidden;
      position: relative;

      @include media("<=tablet") {
        &::after {
          z-index: -1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(32, 32, 32, 0) 0%,
            #202020 100%
          );
        }
      }
    }

    &.active {
      .c-project__body {
        max-height: unset;
        margin-bottom: 96px;
        overflow: hidden;

        @include media("<=tablet") {
          margin-bottom: 0;
        }
      }

      .c-project__header__left {
        @include media("<=tablet") {
          margin-bottom: 16px;
        }
      }

      .c-project__header__right {
        @include media("<=tablet") {
          display: none;
        }
      }
    }
    &__carousel {
      height: 400px;
      display: inline-flex;
      padding-left: 8px;
      cursor: grab;

      &.dragging {
        cursor: grabbing;
      }

      @include media("<=tablet") {
        height: 240px;
        padding-left: 0;
      }
    }

    &__media {
      margin-left: 16px;
      height: 100%;
      pointer-events: none;
      user-select: none;
      position: relative;

      &[data-size="small"] {
        @include ratioHeight(400, 400);

        @include media("<=tablet") {
          @include ratioHeight(240, 240);
        }
      }

      &[data-size="large"] {
        @include ratioHeight(400, 600);

        @include media("<=tablet") {
          @include ratioHeight(240, 360);
        }
      }

      img,
      video {
        pointer-events: none;
        user-select: none;
      }

      img {
        opacity: 0;
      }

      .loader {
        @extend %element-cover;
        background: $dark-stroke;
        color: $light-stroke;
        z-index: inherit;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &__star {
          margin-right: 8px;
        }

        &__copy {
          color: $light-stroke;
          font-size: 20px;
          text-shadow: none;
        }
      }

      &.loaded {
        .loader {
          display: none;
        }

        img {
          opacity: 1;
        }
      }
    }

    &__infoarea {
      margin-top: 40px;
    }

    &__info {
      @include flex-gap(4.8rem);
      display: flex;
      flex-wrap: wrap;
      @extend %section-padding;

      @include media("<=tablet") {
        @include flex-gap(32px);
        flex-direction: column;
      }

      &__item {
        @include media(">tablet") {
          max-width: 312px;
          width: 100%;
        }
      }

      .section__label {
        height: 24px;
        margin-bottom: 16px;
      }
    }

    &__about {
      &__label {
        width: 55px;
      }
    }

    &__role {
      &__label {
        width: 48px;
      }
    }

    &__collab {
      &__label {
        width: 117px;
      }
    }

    &__shouts {
      &__label {
        width: 63px;
      }
    }

    &__link {
      margin-right: 16px;
      pointer-events: auto;
    }
  }

  &__contact {
    margin-top: 200px;
    margin-bottom: 248px;
    @extend %section-padding;

    @include media("<=tablet") {
      margin-top: 96px;
      margin-bottom: 96px;
    }

    &__labels {
      display: flex;

      @include media(">tablet") {
        justify-content: center;
      }

      .section__label {
        height: 24px;
        &:first-of-type {
          width: 55px;
          margin-right: 8px;
        }

        &:last-of-type {
          width: 48px;
        }
      }
    }

    &__text {
      max-width: 684px;
      margin: 0 auto;
      margin-top: 24px;
      text-align: center;

      @include media("<=tablet") {
        text-align: left;
        margin-left: unset;
      }
    }
  }
}
