%ovh {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  img,
  video {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%page-full {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

%element-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
