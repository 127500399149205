%h1-cond {
  font-size: 96px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: calc(-0.025 * 96px);
  font-feature-settings: "case" on;

  @include media("<=tablet") {
    font-size: 64px;
    letter-spacing: calc(-0.025 * 64px);
  }
}

%h2-serif {
  font-family: $font-serif;
  font-size: 48px;
  line-height: 1.15;
  letter-spacing: calc(-0.05 * 48px);
  text-transform: none;

  @include media("<=tablet") {
    font-size: 32px;
    letter-spacing: calc(-0.05 * 32px);
  }

  &::after {
    height: 1.5px;
  }
}

%h2-sans {
  font-family: $font-sans;
  font-size: 48px;
  line-height: 1.15;
  letter-spacing: calc(-0.025 * 48px);
  text-transform: none;

  @include media("<=tablet") {
    font-size: 32px;
    letter-spacing: calc(-0.025 * 32px);
  }

  a {
    @extend %h2-serif;
  }
}

%body {
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: calc(0.02 * 14px);
  font-family: $font-condensed;
  text-transform: uppercase;
}

%body-serif {
  font-family: $font-serif;
  line-height: 1.3;
  letter-spacing: calc(-0.025 * 14px);
  text-transform: none;

  @extend %link--hidden;
}

%body-sans {
  font-family: $font-sans;
  line-height: 1.3;
  letter-spacing: normal;
  text-transform: none;
}

%body-mono {
  font-family: $font-mono;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: normal;
}
