.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("preloader");
  padding: 24px;
  display: flex;
  background: $black;

  @include media("<=tablet") {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__wrapper {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    // max-width: 377px;

    @include media("<=tablet") {
      display: block;
    }
  }

  &__title {
    @extend %h2-serif;
    margin-right: 18px;

    @include media("<=tablet") {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  &__right {
    @include media(">tablet") {
      margin-top: 12px;
    }

    &__top {
      margin-bottom: 32px;
    }

    &__bottom {
      margin-top: 16px;
      max-width: 200px;
      // opacity: 0;
    }
  }

  &__desc {
    margin-bottom: 24px;
    opacity: 0;
    transition: opacity 1s;

    &.active {
      opacity: 1;
    }
  }

  &__button {
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__info {
    margin-top: 23px;
  }

  &__line {
    height: 1px;
    width: 100%;
    background: $dark-stroke;
    transform-origin: left;
    transition: transform 0.25s;
    transform: scaleX(0);
  }

  &__number__con {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-bottom: 8px;
  }

  &__number {
    width: 40px;
    height: 24px;
    @extend %flex-center;
    position: relative;
    @extend %octagon-border;
  }

  &__icon {
    margin-right: 8px;
    display: grid;
  }
}
