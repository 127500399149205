.about {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @extend %cover;
    height: auto;
  }

  &__main {
    margin: 120px auto;
    max-width: 1204px;
    @extend %section-padding;

    @include media("<=tablet") {
      margin-top: 16px;
      margin-bottom: 48px;
    }
  }

  &__hero {
    margin-bottom: 200px;

    @include media("<=tablet") {
      margin-bottom: 96px;
    }

    &__label {
      display: grid;
      margin-bottom: 24px;
      width: 63px;
      height: 24px;
    }

    &__text {
      @extend %h1-cond;
      hyphens: auto;
      word-break: break-word;
      margin-bottom: 24px;

      @include media("<=tablet") {
        margin-bottom: 16px;
      }

      .text-row {
        &__image {
          margin-left: 16px;
          margin-right: 16px;
          display: inline-block;
          max-width: 72px;
          width: 100%;
          @include ratio(1, 1);

          @include media("<=tablet") {
            max-width: 48px;
          }

          &--small {
            img,
            video {
              @extend %img;
              clip-path: polygon(
                0 51,
                0 21,
                21 0,
                51 0,
                72 21,
                72 51,
                51 72,
                21 72,
                0 51
              );

              @include media("<=tablet") {
                clip-path: polygon(
                  34 48,
                  48 34,
                  48 14,
                  34 1,
                  14 1,
                  1 14,
                  1 34,
                  14 48,
                  34 48
                );
              }
            }
          }

          &--large {
            max-width: 144px;
            @include ratio(72, 144);

            @include media("<=tablet") {
              max-width: 96px;
            }

            img,
            video {
              @extend %img;
              clip-path: polygon(
                21 72,
                1 51,
                1 21,
                21 1,
                123 1,
                144 21,
                144 51,
                123 72,
                21 72
              );
            }

            @include media("<=tablet") {
              clip-path: polygon(
                14 48,
                1 34,
                1 14,
                14 1,
                82 1,
                96 14,
                96 34,
                82 48,
                14 48
              );
            }
          }
        }

        .break-mobile {
          @include media("<=phone") {
            word-break: break-all;
          }
        }

        .block-mobile {
          @include media("<=phone") {
            display: block;
          }
        }
      }
    }

    &__desc {
      @extend %body-sans;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include media("<=tablet") {
      flex-direction: column;
    }

    &__left {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      width: 100%;

      @include media(">tablet") {
        max-width: 684px;
        margin-right: 48px;
        margin-bottom: 0;
      }
    }

    &__right {
      width: 100%;
      @include media(">tablet") {
        max-width: 330px;
      }

      &__item {
        margin-bottom: 48px;

        @include media(">tablet") {
          margin-bottom: 96px;
          text-align: center;
        }
      }

      &__footer {
        @include media(">tablet") {
          max-width: 684px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        @include media("<=1096px") {
          max-width: toRem(700);
        }

        @include media("<=tablet") {
          max-width: unset;
        }
      }
    }

    &__image {
      width: 100%;
      @include ratio(440, 330);

      @include media("<=tablet") {
        margin: 48px 0;
      }
    }
  }

  &__bio {
    display: grid;
    margin-bottom: 120px;

    @include media("<=tablet") {
      margin-bottom: 48px;
    }

    &__labels {
      @include flex-gap(8px);

      .section__label {
        height: 24px;
        &:first-of-type {
          width: 55px;
        }

        &:last-of-type {
          width: 32px;
        }
      }
    }

    &__desc {
      margin-top: 24px;
      margin-bottom: 16px;
      @extend %h2-sans;
    }

    &__disclaimer {
      @extend %body-sans;
    }
  }

  &__contact {
    @extend %h2-sans;
  }

  &__label {
    display: inline-flex;
    @extend %octagon-border;
    margin-bottom: 24px;
    height: 24px;

    &--focus {
      width: 55px;
    }

    &--shouts {
      width: 63px;
    }

    &--clients {
      width: 71px;
    }

    &--resume {
      width: 71px;
    }

    &--credits {
      width: 79px;
    }
  }

  &__links {
    &__labels {
      text-align: left;
      @include flex-gap(8px);

      @include media(">tablet") {
        justify-content: center;
      }

      .section__label {
        height: 24px;
        &:first-of-type {
          width: 32px;
        }
        &:not(:first-of-type) {
          width: 40px;
        }
      }
    }

    &__list {
      max-width: 210px;
      margin: 0 auto;
      margin-top: 24px;

      @include media("<=tablet") {
        margin-left: 0;
      }
    }
  }

  &__resume {
    &__item {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      .resume {
        &__link {
          @extend %body-serif;
        }
      }
    }
  }

  &__credits {
    &__item {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      .credits {
        &__link {
          @extend %body-serif;
        }
      }
    }
  }

  &__bonus {
    @extend %body-sans;
    margin-bottom: 24px;
  }

  &__mark {
    background: $washed-green;
    box-shadow: 0px 0px 8px rgba(204, 207, 202, 0.75);
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;

    @include media("<=tablet") {
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__text {
      margin-bottom: 16px;
      @include media(">tablet") {
        max-width: 200px;
        margin-bottom: 14px;
      }
    }

    &__left {
      @include media("<=tablet") {
        margin-bottom: 32px;
      }
    }

    &__icons {
      @include flex-gap(8px);
    }

    &__left,
    &__right {
      display: grid;
    }
  }
}

.section__label {
  @extend %octagon-border;
}
