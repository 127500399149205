.c-nav {
  height: 51px;
  border-bottom: $border-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @extend %section-padding;

  &__link {
    @extend %body-serif;
    text-transform: capitalize;
  }

  &__time {
    width: 86px;
    height: 24px;
    @extend %flex-center;
    position: relative;
    @extend %octagon-border;
    flex-shrink: 0;

    .flash {
      animation: blinker 1s step-start infinite;
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
