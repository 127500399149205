%cover {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

@mixin screenHeight {
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    & {
      max-height: -webkit-fill-available;
    }
  }
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin cursor {
  @media (any-pointer: fine) {
    @content;
  }
}

@mixin cursor-mobile {
  @media (any-pointer: fine) {
    @content;
  }

  @include media(">tablet") {
    @content;
  }
}

@mixin ratio($height, $width) {
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: calc($width / $height);

  @supports not (aspect-ratio: calc($width / $height)) {
    &:after {
      content: "";
      display: inline-block;
      padding-top: calc($height / $width) * 100%;
      width: 100%;
    }
  }

  img,
  video {
    @extend %img;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@mixin ratioHeight($height, $width) {
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: calc($width / $height);

  @supports not (aspect-ratio: calc($width / $height)) {
    width: $width;
    height: $height;
  }

  img,
  video {
    @extend %img;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@mixin flex-gap($row-gap, $column-gap: $row-gap) {
  display: inline-flex;
  gap: $row-gap $column-gap;

  @supports not (gap: $row-gap $column-gap) {
    --row-gap: #{$row-gap};
    --column-gap: #{$column-gap};
    margin: calc(-1 * var(--row-gap)) 0 0 calc(-1 * var(--column-gap));
    width: calc(100% + var(--column-gap));

    > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}
