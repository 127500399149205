.c-button {
  @extend %body-serif;
  cursor: pointer;
  border: $border-light;
  border-radius: 40px;
  padding: 11px 16px;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  transition: border-color 0.25s, color 0.25s, opacity 1s 0.5s;

  &::after {
    display: none;
  }

  @media (any-pointer: fine) {
    &:hover {
      filter: drop-shadow(0px 0px 8px rgba(144, 255, 33, 0.5));
      box-shadow: 0px 0px 8px rgba(144, 255, 33, 0.5);
      color: $green;
      border: $border-green;
    }
  }

  .c-button__icon {
    display: grid;
    margin-left: 4px;
  }
}
