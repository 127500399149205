// colors
$white: #ffffff;
$black: #080808;
$washed-green: #cccfca;
$dark-stroke: #202020;
$light-stroke: #484b45;
$green: #90ff21;

// fonts
$font-condensed: "Suisse Condensed", -apple-system, BlinkMacSystemFont, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI,
  sans-serif;
$font-sans: "Suisse Intl", sans-serif;
$font-mono: "Suisse Mono", monospace;
$font-serif: "Suisse Works", serif;

// border
$border-dark: 1px solid $dark-stroke;
$border-light: 1px solid $light-stroke;
$border-green: 1px solid $green;

// easings
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

// fonts

// z-index
$z-indexes: (
  "noscript",
  "smoke",
  "preloader",
  "cursor",
  "navigation",
  "content",
  "canvas",
  "4",
  "3",
  "2",
  "1"
);
