.cursor {
  display: none;
  @extend %body-mono;

  &__item {
    grid-area: 1/1;
    backdrop-filter: blur(32px);
    background: rgba(255, 255, 255, 0.5);
    color: #000000;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__expand {
    clip-path: polygon(0 17, 0 7, 7 0, 56 0, 63 7, 63 17, 56 24, 7 24, 0 17);
    width: 63px;
  }

  &__collapse {
    opacity: 0;
    clip-path: polygon(0 17, 0 7, 7 0, 72 0, 79 7, 79 17, 72 24, 7 24, 0 17);
    width: 79px;
  }

  &.active {
    .cursor__expand {
      opacity: 0;
    }

    .cursor__collapse {
      opacity: 1;
    }
  }
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    pointer-events: none;
    z-index: z("cursor");
    opacity: 1;

    @include media("<=tablet") {
      display: none;
    }
  }

  .no-js .cursor {
    display: none;
  }
}
