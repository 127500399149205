html {
  box-sizing: border-box;
  font-size: calc(100vw / 1440 * 10);
  height: 100%;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  width: 100%;

  @include media("<=375px") {
    font-size: calc(100vw / 375 * 10);
  }

  @include media(">tablet") {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

// resets
body {
  margin: 0;
  padding: 0;
  font-family: $font-condensed;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: calc(0.02 * 14px);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overscroll-behavior: none;
  width: 100%;
  background: $black;
  color: $washed-green;
  text-shadow: 0px 0px 8px rgba(204, 207, 202, 0.75);

  @include media(">tablet") {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  &.no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
  }

  * {
    &::selection {
      color: $black;
      background: $green;
      text-shadow: none;
    }
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z("canvas");
  pointer-events: none;

  &.smoke {
    z-index: z("smoke");

    @include media("<=tablet") {
      opacity: 0;
    }
  }
}

.dg.ac {
  z-index: 10 !important;
}

p {
  a {
    @extend %body-serif;
  }
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(
    1px,
    1px,
    1px,
    1px
  ); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(
    50%
  ); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}
