.g-256 {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @extend %cover;
    height: auto;

    &__inner {
      display: flex;
      height: 100vh;
      flex-direction: column;

      .c-nav {
        flex-shrink: 0;
      }
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $dark-stroke;
    @extend %h1-cond;
    text-align: center;
    text-shadow: none;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-basis: 100%;

    .d-card {
      width: auto;
      position: absolute;
      z-index: z("1");
      @extend %body-mono;

      &__inner {
        position: absolute;
        width: auto;
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        overflow: hidden;
        will-change: transform;
        background: $dark-stroke;

        &.is-pointer-down {
          .d-card__header {
            cursor: grabbing;
          }
        }
      }

      &__header {
        padding-top: 8px;
        padding-bottom: 12px;
        background: $dark-stroke;
        width: auto;
        cursor: grab;
        position: relative;
        text-align: center;

        &__title {
        }

        &__buttons {
          display: flex;
          position: absolute;
          left: 8px;
          top: 10px;
        }

        &__button {
          width: 12px;
          height: 12px;
          border-radius: 50%;

          &:first-child {
            margin-right: 4px;
            background: $light-stroke;
          }

          &:last-child {
            background: $dark-stroke;
            border: $border-light;
          }
        }
      }

      &__content {
        background: $dark-stroke;
        width: 100%;
        padding: 8px;
        padding-top: 0;
        display: block;

        &.small {
          width: 176px;
          height: 168px;
        }

        &.medium {
          width: 256px;
          height: 248px;
        }

        .d-card__image {
          width: 100%;
          height: 100%;
          img {
            @extend %img;
          }
        }

        &.auto {
          .d-card__image {
            max-height: 256px;
            img {
              max-height: 256px;
              height: auto;
              width: auto;
            }
          }
        }
      }
    }
  }

  &__footer {
    margin-top: auto;
  }
}
